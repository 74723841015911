import { BrowserRouter, Routes, Route } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import './App.css';
import Menu from './components/Menu';
import Footer from "./components/Footer";
import Home from "./pages/home";
import Pagos_locales from "./pages/pagos_locales";
//import Pagos_internacionales from "./pages/pagos_internacionales";
import Integraciones_ecommerce from "./pages/integraciones_ecommerce";
import Sobre_nosotros from "./pages/sobre_nosotros";
import Contacto from "./pages/contacto";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <Menu />
        </header>
        <div className='body'>
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/pagos_locales" element={<Pagos_locales />} />
            {/* <Route path="/pagos_internacionales" element={<Pagos_internacionales />} />*/}
            <Route path="/integraciones_ecommerce" element={<Integraciones_ecommerce />} /> 
            <Route path="/sobre_nosotros" element={<Sobre_nosotros />} />
            <Route path="/contacto" element={<Contacto />} />
          </Routes>
          <Footer />
        </div>
        <div className="upIcon">
          <a href="#part-one">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
            </svg>
          </a>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
