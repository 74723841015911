import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css'


function Sobre_nosotros() {

    return (
        <Container className='contenedorinicial'>
            <Row><div className="ag_title">SOBRE NOSOTROS</div></Row>

            <Row>
                <Col>
                    <p className="ag_parrafo">
                        En Paytowns te brindamos servicios comerciales para guiarte en el procesamiento de pagos de bajo costo.
                    </p>
                    <p className="ag_parrafo">
                        Satisfacemos las necesidades de tu negocio local. 
                    </p>
                    <p className="ag_parrafo">
                        Nuestro equipo de profesionales expertos te guiará para garantizar el mejor servicio y la seguridad en el procesamiento de pagos.
                    </p>
                    <p className="ag_parrafo">
                        Los servicios de pago proporcionados permitirán que tu empresa acepte tarjetas en cualquier lugar y en todo momento.
                    </p>
                </Col>
                <Col className="box-imgag_container" xs={12} md={6}>
                                <img className='section-img' src="/assets/img/pagos-locales.png" alt="" />
                        </Col>
            </Row>
            
            {/* <div class="card text-center">
                <div class="card-header">
                    PAGOS LOCALES
                </div>
                <div class="card-body">
                    <h5 class="card-title">Special title treatment</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
                <div class="card-footer text-muted">
                    2 days ago
                </div>
            </div>



            <Row>
                <Col className='box-text text-center' xs={12} md={6}>
                    <h2>PAGOS LOCALES</h2>
                </Col>
    </Row> */}
        </Container>
    );
};

export default Sobre_nosotros;