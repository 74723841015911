import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css'


function Home() {

    return (
        <div>
            <div  id='part-one' className='part-one'>
                <Container>
                    <Row>
                        <Col className='box-text text-center' xs={12} md={6}>
                                <h2>ACEPTA TODAS LAS TARJETAS DE CRÉDITO</h2>
                                <h1 className='text-blue'>EN TU NEGOCIO FÍSICO O DIGITAL</h1>
                                <Button>Solicite mas información</Button>
                        </Col>
                        <Col className="box-img" xs={12} md={6}>
                                <img className='img-front' src="/assets/img/Home/Layer-1.jpg" alt="" />
                                <img className='img-back' src="/assets/img/Home/objet1.png" alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='part-two'>
                <Row>
                    <Col className="box-img" xs={12} md={6}>
                            <img  src="/assets/img/Home/part-two/img.png" alt="" />
                    </Col>
                    <Col className='box-text' xs={12} md={6}>
                            <h1 className='text-blue'>En cualquier parte</h1>
                            <p >Envia enlaces elentrónicos a tus clientes para recibir tus pagos en cualquier país del mundo</p>
                    </Col>
                </Row>
            </Container>
            <div className='part-three'>
                <Container>
                    {/* <h2>Titulo</h2> */}
                    <Row className='items' >
                        <Col className="box-item" xs={12}  sm={6} md={3} gl={4}>
                            <div className="box">
                                <center><img src="/assets/img/Home/part-three/item-1.png" alt="" /></center>
                                <center><h4 className='text-blue' >CUALQUIER TARJETA</h4></center>
                                <p> Acepta todas las tarjetas del mercado</p>
                            </div>
                        </Col>
                        <Col className="box-item" xs={12}  sm={6}  md={3} gl={4}>
                            <div className="box">
                                <center><img src="/assets/img/Home/part-three/item-2.png" alt="" /></center>
                                <center><h4 className='text-blue' >TOTALMENTE SEGURO</h4></center>
                                <p>pagos seguros</p>
                            </div>
                        </Col>
                        <Col className="box-item" xs={12} sm={6} md={3} gl={4}>
                            <div className="box">
                                <center><img src="/assets/img/Home/part-three/item-3.png" alt="" /></center>
                                <center><h4 className='text-blue' >TIEMPO RECORD</h4></center>
                                <p>Empieza a recibir pagos a tus clientes en tiempo record</p>
                            </div>
                        </Col>
                        <Col className="box-item" xs={12}  sm={6}  md={3} gl={4}>
                            <div className="box">
                                <center><img src="/assets/img/Home/part-three/item-4.png" alt="" /></center>
                                <center><h4 className='text-blue' >DIRECTO</h4></center>
                                <p>Liquidaciones automáticas directo a tu cuenta bancaria</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='part-four'>
                <Row>
                    <Col className='box-text' xs={12} md={6}>
                            <h1>Escala tu negocio con la ayuda de paytowns</h1>
                            <Button>Comienza ahora</Button>
                    </Col>
                    <Col className="box-img" xs={12} md={6}>
                            <img  src="/assets/img/Home/part-four/img.png" alt="" />
                    </Col>
                </Row>
            </Container>
            <div className="part-five">
                <Container>
                    <Row>
                        <Col className='box-text' xs={12} md={6}>
                                <h1><span>¿</span>Quieres empezar a recibir pagos de clientes por medio de tarjeta<span>?</span></h1>
                        </Col>
                        <Col className="box-button" xs={12} md={6}>
                                <Button>Contacta a un asesor</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='part-six'>
                <Row>
                    <Col className="box-img" xs={12} md={6}>
                            <img  src="/assets/img/Home/part-six/img.png" alt="" />
                    </Col>
                    <Col className='box-text' xs={12} md={6}>
                            <h1 className='text-blue'>Consulta con nuestra plataforma el estado de todas tus transacciones en cualquier momento.</h1>
                            <Button>Comienza ahora</Button>
                    </Col>
                </Row>
            </Container>
            <Container className='part-sevent'>
                {/* <h1>Revoluciona tu negocio con nuestras soluciones en línea</h1> */}
                <Row>
                    <Col xs={12} md={4}><img src="/assets/img/Home/part-sevent/img-1.png" alt="" /></Col>
                    <Col xs={12} md={4}><img src="/assets/img/Home/part-sevent/img-2.png" alt="" /></Col>
                    <Col xs={12} md={4}><img src="/assets/img/Home/part-sevent/img-3.png" alt="" /></Col>
                </Row>
            </Container>
            <div className="part-eight">
                <Container>
                    <div className='body'>
                        <Row>
                            <Col className='box-text text-blue' xs={12} md={6}>
                                <h1>Revoluciona tu negocio con nuestras soluciones en línea</h1>
                                <Button>Comienza ahora</Button>
                            </Col>
                            <Col className="box-img" xs={12} md={6}>
                                    <img  src="/assets/img/Home/part-eight/img.png" alt="" />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            
        </div>
    );
};

export default Home;