import React from 'react';
import './styles.css'
import { Navbar, Nav, Container } from 'react-bootstrap';
// NavDropdown,

function Menu() {
  

    return (
        <Navbar collapseOnSelect  expand="lg" bg="white" fixed="top" variant="white">
          <Container>
            <Navbar.Brand href="/">
              <img
              src="/assets/img/logo-menu.png"  // Reemplaza con la ruta de tu imagen
              width="85"
              height="75"
              className="d-inline-block align-top"
              alt="Logo"
              />
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link className='nav-item' href="pagos_locales">Pagos locales</Nav.Link>
                <Nav.Link className='nav-item' href="pagos_internacionales">Pagos Internacionales</Nav.Link>
                <Nav.Link className='nav-item' href="integraciones_ecommerce">Integraciones con Ecommerce</Nav.Link>
                <Nav.Link className='nav-item' href="sobre_nosotros">Sobre nosotros</Nav.Link>
                <Nav.Link className='nav-item' href="contacto">Contacto</Nav.Link>
                {/* <NavDropdown title="Más opciones" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Opción 1</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Opción 2</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.3">Separador</NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    );
};

export default Menu;